<script>
import appConfig from "@/app.config";
import {mapGetters} from 'vuex'
import moment from 'moment'

/**
 * Maintenance component
 */
export default {
  page: {
    title: "Bill Expired",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  async created() {
    if(!this.billingExists.hasOwnProperty("updated_at")){
      this.$router.push({name : 'payment', query : {appRoute : this.$route.query.appRoute}});
    }
  },
  computed : {
    ...mapGetters({
        billingExists : 'getBillingExists',
        monthName : 'getMonthName'
    }),
    
    tanggalExpire() {
      const date_transaction = moment(this.billingExists.updated_at);
      return `${date_transaction.date().toString().padStart(2, '0')} ${this.monthName[date_transaction.month()]} ${date_transaction.year()}`
    }
  }
}; 
</script>
<template>
  <div style="overflow: hidden;">
    
    <section class="my-5">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="home-wrapper" style="padding-left: 20px!important; padding-right: 20px!important;">
              <!-- <div class="mb-5">
                <div class="d-block auth-logo" style="display: flex !important; align-items: center; justify-content: center;">
                  <img
                    src="@/assets/images/brands/logo_ss.png"
                    alt=""
                    height="60"
                    class="auth-logo-dark mb-3"
                  />
                  <img
                    src="@/assets/images/brands/logo_ss.png"
                    alt=""
                    height="60"
                    class="auth-logo-light mb-3"
                  />
                  <h3 style="margin-left: 1rem;">Software Sekolah</h3>
                </div>
              </div> -->

              <div class="row justify-content-center">
                <div class="col-sm-8">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/bill-expired.png"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-5 text-center">
                <router-link class="btn btn-primary" :to="{name : 'payment', query : {appRoute : this.$route.query.appRoute}}">Kembali ke Daftar Tagihan</router-link>
              </div>

              <h3 class="mt-5">Kode Bayar Kadaluarsa</h3>
              <p style="padding: 10px">Maaf, kode pembayaran sudah tidak aktif sejak {{tanggalExpire}}<br>Hal tersebut kemungkinan terjadi dikarenakan beberapa hal.<br>Diantaranya karena :</p>

              <div class="row">
                <div class="col-sm-12">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-time mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        Terlambat Bayar
                      </h5>
                      <p class="text-muted mb-0">
                        Anda belum melunasi pembayaran melalui channel pembayaran yang anda pilih hingga batas akhir waktu maksimal pembayaran
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-money mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        Perubahan Nominal
                      </h5>
                      <p class="text-muted mb-0">
                        Pihak sekolah telah mengubah nominal bayar anda
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-edit mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        Perubahan Tagihan
                      </h5>
                      <p class="text-muted mb-0">
                        Pihak sekolah telah menghapus tagihan yang harus anda bayar atau melunasinya melalui pembayaran tunai di sekolah
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
